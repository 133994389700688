import React, { useState } from 'react'

function SidebarLink({ href, children, dropdown, className }) {
    const [submenu, toggleSubmenu] = useState(false)
    return (
        <>
            {
                dropdown ?
                    <button className='w-full relative text-color-text-dark' onClick={dropdown ? () => toggleSubmenu(!submenu) : null}><li className='flex items-center relative hover:bg-[#F1F1F1] py-6 px-5 text-base'>{children} </li></button>
                    :
                    <a className={`relative text-black ${className}`} href={href}><li className={`flex items-center relative hover:bg-[#F1F1F1] py-6 px-5 text-base`}>{children}</li></a>
            }

            {dropdown?
                <div className={`animated__sidebar overflow-hidden flex flex-col w-full top-full z-[99] bg-white ${submenu ? 'max-h-screen' : 'max-h-0'}`}>
                    <ul className='pl-8 xl:pl-0'>
                        {
                            dropdown?.map(link => {
                                return <SidebarLink dropdown={link.submenu} href={link.href}>{link.label}</SidebarLink>
                            })
                        }
                    </ul>
                </div>
                :null
            }
        </>

    )
}

export default SidebarLink