import React from 'react';
import Section from "../../components/Section";
import Headline from "../../components/Headline";
import CallToAction from "../../components/CallToAction";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow-long.svg";
import Multicolumn from "../../sections/Multicolumn";
import {websiteReasons} from "../../utils/multicolumn-data";
import CTA from "../../sections/CTA";
import ContactForm from "../../components/contact/ContactForm";
function WebsiteCreation(props) {
    return (
        <div className='mt-32'>
            <Section name="about-hero">
                <div className="page__width flex flex-col items-center">
                    <Headline>You just got your first product shipped! Now what?</Headline>
                    <p className='mt-4 w-full lg:w-2/3 text-center mx-auto text-color-text-light'>
                        The second step of your import journey is to sell your freshly imported products somewhere online. You don’t want your products to be stuck in your inventory without anyone knowing about their existence.</p>
                    <CallToAction behaviour="link" className="border-none xl:flex items-center mt-4 lg:w-auto font-bold text-center text-brand group" hover="hover:underline" href="#let-us-help">Start Selling Now<ArrowIcon className='hidden lg:block ml-2 group-hover:ml-4 w-4 h-4 transition-all duration-500 stroke-brand'/></CallToAction>
                </div>
            </Section>
            <Section name="let-us-help">
                <div className="w-full lg:w-2/3 mx-auto bg-ecom bg-cover bg-center rounded-lg px-8 py-16 lg:px-16 lg:py-24">
                    <Headline className='text-start text-white'>Let us handle the stress.</Headline>
                    <p className='mt-4 text-white'>
                        Creating a website from scratch is indeed a daunting task and takes way too much time from an entrepreneur like you. GoodyGo’s design and development team is on the job to create a unique online ecommerce store where you can provide value to the world through your products. All that while you sit back and focus on the important business decisions.</p>
                </div>
            </Section>
            <Section name="offer">
                <Multicolumn headline="What can we offer?" data={websiteReasons}/>
            </Section>
            <Section>
                <Headline>Have Questions? Ask Right Away!</Headline>
                <ContactForm className="w-full lg:w-2/3 mx-auto"/>
            </Section>
            <Section name="CTA">
                <CTA />
            </Section>
        </div>
    );
}

export default WebsiteCreation;