import React from 'react'
import ImageWithText from '../sections/ImageWithText'
import Section from '../components/Section'
import Content from "../sections/Content";
import {ReactComponent as ThankYouIcon} from "../assets/icons/thank-you.svg";
import {ReactComponent as ArrowIcon} from "../assets/icons/arrow-long.svg";
import CTA from "../sections/CTA";
import { reasons } from "../utils/multicolumn-data";
import Multicolumn from "../sections/Multicolumn";
import Headline from "../components/Headline";
import CallToAction from "../components/CallToAction";
import ImageWrapper from "../components/ImageWrapper";
import aboutShip from '../assets/img/about-ship.png'
import shipBlob from '../assets/img/about-excellence.png'

function About() {
    return (
        <div className='mt-32'>
            <Section name="about-hero">
                <div className="page__width flex flex-col items-center">
                    <Headline>Our Story of Connecting Hustle with Helping</Headline>
                    <p className='mt-4 w-full lg:w-2/3 text-center mx-auto text-color-text-light'>
                        Welcome to our world of import solutions crafted from experience, passion, and the desire to make your import journey seamless. We are a dynamic duo, brought together by a shared hustle and a common goal: to help businesses like yours thrive through efficient and effective importing.
                    </p>
                    <CallToAction behaviour="link" className="border-none xl:flex items-center mt-4 lg:w-auto font-bold text-center text-brand group" hover="hover:underline" href="#beginnings">About GoodyGo<ArrowIcon className='hidden lg:block ml-2 group-hover:ml-4 w-4 h-4 transition-all duration-500 stroke-brand'/></CallToAction>
                    <ImageWrapper src={aboutShip} className="hidden lg:block w-full mt-4 rounded-lg" />
                </div>
            </Section>
            <Section name="beginnings">
                <div className="w-full lg:w-2/3 mx-auto flex flex-col">
                    <Headline className='text-start'>Our Humble Beginnings</Headline>
                    <p className='mt-4 text-color-text-light'>
                        Back in the early days, we were just like you - driven entrepreneurs with a dream to bring exciting products to our market. But the path to success wasn't a straight line. We faced challenges, hit roadblocks, and learned the hard way. Importing goods seemed like an overwhelming maze, and we knew there had to be a better way.
                    </p>
                </div>
                <div className="w-full lg:w-2/3 mx-auto mt-12 flex flex-col">
                    <Headline className='text-start'>Turning Struggles Into Expertise</Headline>
                    <p className='mt-4 text-color-text-light'>
                        With every misstep and triumph, we gained insights that transformed our journey. Our early struggles with importing became the foundation of our expertise. Through dedication and determination, we turned failures into stepping stones toward our ultimate goal: making importation accessible, efficient, and profitable for all
                    </p>
                </div>
            </Section>
            <Section name="four-years">
                <div className="w-full lg:w-2/3 mx-auto bg-about-navy bg-cover rounded-lg px-8 py-16 lg:px-16 lg:py-24">
                    <Headline className='text-start text-white'>Four Years of Learning, Growing, and Succeeding</Headline>
                    <p className='mt-4 text-white'>
                        Fast forward four years, and what was once a daunting task has become second nature. Our experience is built on a rich history of successes and failures. Every challenge we conquered, every shipment that arrived flawlessly, and every partnership we forged-they've all shaped us into the import aficionados we are today.
                    </p>
                </div>
            </Section>
            <Section name="effortless-excellence">
                <div className="w-full lg:w-2/3 mx-auto">
                    <ImageWithText
                        imageFirst
                        image={shipBlob}
                        heading="Effortless Excellence"
                        content="After years of learning and perfecting our craft, we've reached a point where importing goods seems effortless. But don't mistake this effortlessness for lack of hard work; it's a testament to the countless hours spent refining our processes, building relationships, and mastering the art of successful importing."
                    />
                </div>
            </Section>
            <Section name="our-mission">
                <div className="w-full lg:w-2/3 mx-auto bg-[#F9F9F9] lg:bg-about-gray bg-cover bg-center lg:bg-bottom rounded-lg px-8 py-16 lg:px-16 lg:py-24">
                    <Headline className='lg:w-2/3 text-start'>Our Mission: Connecting Hustle with Helping</Headline>
                    <p className='mt-4 lg:w-2/3 text-color-text-light'>
                        Our mission is to empower businesses just like yours. We've walked in your shoes, and we understand the hurdles you face. Our passion lies in sharing our knowledge and expertise, helping you navigate the complexities of importing, and turning your aspirations into accomplishments.
                    </p>
                </div>
            </Section>
            <Section name="join-us">
                <Content
                    headline="Join Us on Your Import Journey"
                    content="Whether you're a seasoned importer seeking smoother processes or a newcomer looking to break into the global market, we're here to be your partners in success. Together, we'll transform challenges into opportunities and make importing a journey of growth and prosperity."
                    imageFirst
                    cta="Get a Quote Now"
                    href="/quote"
                />
            </Section>
            <Section name="why-us">
                <Multicolumn headline="Why Choose Us?" data={reasons}/>
            </Section>
            <Section name="thank-you">
                <ThankYouIcon className="w-32 h-32 mx-auto my-8 fill-brand"/>
                <Content
                    headline="Thank you for choosing us to be a part of your story"
                    content="Let's connect hustle with helping and create import success, one shipment at a time."
                    secondParagraph="~ Yixun Wang, Dawid Dzikowski | Co-Founders, GoodyGo"
                />
            </Section>
            <Section name="CTA">
                <CTA />
            </Section>
        </div>
    )
}

export default About