import React from 'react';
import Process from "../../sections/Process";
import Section from "../../components/Section";
import FAQ from "../../sections/FAQ";
import Headline from "../../components/Headline";
import ContactForm from "../../components/contact/ContactForm";
import CTA from "../../sections/CTA";
import ImageWithText from "../../sections/ImageWithText";
import partialImport from '../../assets/img/partial-import.png'
import Multicolumn from "../../sections/Multicolumn";
import {partialImportBenefits} from '../../utils/multicolumn-data'
import Content from "../../sections/Content";

function FullImport() {
    return (
        <>
            <div className='mt-32'></div>
            <Section name="process">
                <Process />
            </Section>
            <Section name="process">
                <ImageWithText
                    image={partialImport}
                    heading="Partial Import from China"
                    content="At GoodyGo, we understand that every business is unique and has its own set of requirements when it comes to importing. While our full import services cater to businesses looking for end-to-end solutions, our Partial Import service ensures flexibility at every step. Just like our full import model, with Partial Import, you get the same dedication and expertise, but tailor-made to suit the specific stages you need assistance with. Whether it's sourcing products, handling logistics, managing paperwork, or any other aspect of the import process, GoodyGo is here to assist."
                />
            </Section>
            <Section name="FAQ">
                <Multicolumn headline="Why choose GoodyGo's Partial Import?" data={partialImportBenefits}/>
            </Section>
            <Section name="FAQ">
                <Content headline="Your Partner in Importing" content="We celebrate the diversity of business needs and understand the intricacies involved in the importing process. That's why at GoodyGo, we're not just a service provider; we're your trusted partner at every stage. Our commitment is to simplify, optimize, and make your importing journey a success."/>
            </Section>
            <Section name="FAQ">
                <FAQ/>
            </Section>
            <Section name="CTA">
                <CTA/>
            </Section>
            <Section>
                <Headline className='mb-8'>Have Questions? Ask Right Away!</Headline>
                <ContactForm className="w-full lg:w-2/3 mx-auto"/>
            </Section>
        </>
    );
}

export default FullImport;