const reasons = [
    {
        icon: "china",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "We are located in China",
        content: "GoodyGo’s headquarters are located in China for easier import & export & 100x more efficient workflow."
    },
    {
        icon: "link",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Independence",
        content: "Unfortunately, there are many agencies that outsource contractors to earn money on brokering. We don’t act this way - We manage everything by ourselves."
    },
    {
        icon: "experience",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Years of Experience",
        content: "With extensive firsthand import experience, we guide businesses to import seamlessly from China to Europe and the US."
    },
]

const solutions = [
    {
        icon: "card",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Hassle-Free Payments",
        content: "We setup a payment gateway between you and 1688.com, so you don’t have to use Chinese RMB"
    },
    {
        icon: "checklist",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Sample Check",
        content: "We check the sample product for any defects and send you real  photos as soon as they arrive at our warehouse in China."
    },
    {
        icon: "language",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "No Language Barrier",
        content: "Our team speaks Chinese and acts as a middleman between you and the suppliers. Your questions will always be answered."
    },
]
const websiteReasons = [
    {
        icon: "design",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Website Design",
        content: "Our design department will start off by analyzing your business, market, target audience, and come up with a unique brand identity for your website."
    },
    {
        icon: "development",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Website Development",
        content: "Moving on, our team of developers is always online to develop any kind of website with a marketing purpose. Landing pages, Sales Pages, Portfolios - You name it."
    },
    {
        icon: "ecommerce",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Ecommerce Store",
        content: "If you have just started importing products from China and need a store to sell them - worry not! GoodyGo is here to create a full-fledged online store with everything needed."
    },
]

const partialImportBenefits = [
    {
        icon: "expertise",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Expertise at Every Stage",
        content: "Our team of experts can jump in at any phase of your import process, ensuring seamless integration with your existing procedures."
    },
    {
        icon: "cost-efficient",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Cost-Efficient",
        content: "Only pay for the stages you require assistance with, making it a cost-effective solution for your business."
    },
    {
        icon: "adaptive",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Adaptive",
        content: "Whether you need help at the beginning, middle, or final stages of your import, we adapt to your needs."
    },
]
const chinaTrademarkBenefits = [
    {
        icon: "expertise",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Detailed Trademark Assessment",
        content: "Before initiating the process, we assess the viability of your trademark in China, ensuring there are no potential conflicts or challenges."
    },
    {
        icon: "checklist",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Efficient Documentation",
        content: "We handle all paperwork and submissions meticulously, aligning with China's regulatory standards and expectations."
    },
    {
        icon: "adaptive",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Regular Updates",
        content: "Stay informed with real-time updates on the status of your trademark application, ensuring transparency at every stage."
    },
]
const hongkongTrademarkBenefits = [
    {
        icon: "world",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Global Relevance",
        content: "Hong Kong serves as a gateway to the vast markets of Asia, offering a blend of Eastern agility with Western business practices."
    },
    {
        icon: "card",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Tax Benefits",
        content: "Known for its favorable tax regime, Hong Kong offers competitive corporate tax rates and absence of capital gains tax, VAT, or sales tax."
    },
    {
        icon: "ease",
        iconStyle:"w-auto h-24 m-4 fill-brand",
        heading: "Ease of Business",
        content: "With our expertise, EU citizens will find that setting up a company in Hong Kong is straightforward and efficient, despite being miles away from home."
    },
    {
        icon: "ownership",
        iconStyle:"w-auto h-24 m-4 stroke-brand",
        heading: "Full Ownership",
        content: "Even as a non-resident, EU citizens can own 100% of their company in Hong Kong"
    },
]

export { partialImportBenefits, hongkongTrademarkBenefits, chinaTrademarkBenefits, websiteReasons, reasons, solutions }