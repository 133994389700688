import React from 'react';
import CallToAction from "../components/CallToAction";
import Headline from "../components/Headline";

function Content({headline, content, cta, href, largeHeading, secondParagraph}) {
    return (
        <div className='flex flex-col items-center gap-4 text-center'>
            <Headline className='mb-8' large={largeHeading}>{headline}</Headline>
            <p className='reveal-fade text-color-text-light w-full lg:w-3/4 text-lg -mt-6'>{content}</p>
            {secondParagraph?<p className='reveal-fade text-color-text-light w-full lg:w-3/4 text-base mt-0'>{secondParagraph}</p>:null}
            <CallToAction type="link" href={href} className="text-lg font-bold text-color-text-dark underline pt-0">{cta}</CallToAction>
        </div>
    );
}

export default Content;