import React from 'react';
import ImageWithText from "../../../sections/ImageWithText";
import Section from "../../../components/Section";
import chinaBlob from '../../../assets/img/company-china.png'
import ServicesAccordion from "../../../components/ServicesAccordion";
import { chinaTrademark } from "../../../utils/services";
import Content from "../../../sections/Content";
import CTA from "../../../sections/CTA";
import Multicolumn from "../../../sections/Multicolumn";
import { chinaTrademarkBenefits } from "../../../utils/multicolumn-data";

function China() {
    return (
        <>
            <div className='mt-32'></div>
            <Section name='china'>
                <ImageWithText heading="Solidify Your Brand's Presence in the World's Largest Marketplace."
                               content="The bustling markets of China offer boundless opportunities, but they also come with challenges, particularly when safeguarding your brand. With GoodyGo, navigate the intricacies of Chinese trademark registration with expertise and ease."
                               image={chinaBlob}
                />
            </Section>
            <Section>
                <ServicesAccordion headline="Who should consider Trademark Registration in China?" data={chinaTrademark} />
            </Section>
            <Section>
                <Content content="By registering a trademark in China, you're not just protecting a name or logo; you're securing your business's future, reputation, and potential for growth in one of the world's most dynamic markets."/>
            </Section>
            <Section>
                <Multicolumn data={chinaTrademarkBenefits} headline="GoodyGo's Expertise in Chinese Trademark Registration"/>
            </Section>
            <Section>
                <CTA />
            </Section>
        </>
    );
}

export default China;