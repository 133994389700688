function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export async function sendContactForm(formName, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": formName, ...data })
        })
            .then(() => {
                resolve()
            })
            .catch(err => {
                reject(err)
            })
    })
}