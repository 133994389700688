import React from 'react';
import Section from "../../../components/Section";
import ImageWithText from "../../../sections/ImageWithText";
import hkBlob from "../../../assets/img/company-hongkong.png";
import ServicesAccordion from "../../../components/ServicesAccordion";
import { hongkongCompany } from "../../../utils/services";
import Multicolumn from "../../../sections/Multicolumn";
import { hongkongTrademarkBenefits } from "../../../utils/multicolumn-data";
import CTA from "../../../sections/CTA";

function HongKong() {
    return (
        <>
            <div className='mt-32'></div>
            <Section name='hongkong'>
                <ImageWithText heading="Embrace the Business Potential of Asia's World City."
                               content="Hong Kong, with its strategic position, stable economy, and pro-business environment, offers enticing prospects for entrepreneurs. For EU citizens, the allure of this global financial hub is unmistakable. GoodyGo is here to guide European entrepreneurs through the nuances of company registration in Hong Kong."
                               image={hkBlob}
                />
            </Section>
            <Section>
                <ServicesAccordion data={hongkongCompany} headline="Company Registration in Hong Kong with GoodyGo: For Whom?"/>
            </Section>
            <Section>
                <Multicolumn data={hongkongTrademarkBenefits} headline="Why Register Your Company in Hong Kong as an EU Citizen?"/>
            </Section>
            <Section>
                <CTA heading="Ready to elevate your business prospects in Asia?"
                     content="Hong Kong beckons with unparalleled opportunities for growth, innovation, and success. With GoodyGo by your side, EU citizens can transition smoothly into the Hong Kong business landscape, reaping the benefits of this dynamic metropolis."
                     cta="Contact GoodyGo Today!"
                />
            </Section>
        </>
    );
}

export default HongKong;