import React from 'react'
import { Field, Form, Formik } from 'formik';
import CallToAction from '../CallToAction'
import * as Yup from "yup";
import Input from "./Input";
import {sendContactForm} from "../../utils/contactService";
import {redirect, useNavigate} from "react-router-dom";

const schema = Yup.object().shape({
    // company: Yup.string().required(),
    // VAT: Yup.string().required(),
    // website: Yup.string().required(),
    // email: Yup.string()
    //     .email("Invalid email.")
    //     .required("Email is required."),
    // firstName: Yup.string().required(),
    // lastName: Yup.string().required(),
    // phoneNr: Yup.string().required(),
    // country: Yup.string().required(),
    // address:Yup.string().required(),
    // experience: Yup.string().required(),
    // industry: Yup.string().required(),
    // product: Yup.string().required(),
    // investment: Yup.string().required(),
    // expectations: Yup.string().required(),
    // contactMethod: Yup.string().required(),
    // comment: Yup.string().required(),
});

function QuoteForm(props) {
    const navigate = useNavigate()
    return (
        <>
            <Formik
                initialValues={{
                    "bot-field": "",
                    "form-name": "quote",
                    company: '',
                    VAT: '',
                    website: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    country: '',
                    address:'',
                    experience: '',
                    industry: '',
                    product: '',
                    investment: '',
                    expectations: '',
                    contactMethod: '',
                    comment: '',
            }}
                validationSchema={schema}
                onSubmit={
                    (values, actions) => {
                        sendContactForm("quote", values)
                            .then(() => {
                                console.log("Form sent.")
                                actions.resetForm()
                                navigate('/thankyou')
                            })
                            .catch((err) => {
                                console.log("An error has occurred: ", err)
                            })
                            .finally(() => actions.setSubmitting(false))
                    }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form data-netlify={true} netlify={true} name="contact" onSubmit={handleSubmit} {...props}>
                        <Field type="hidden" name="bot-field" />
                        <Field type="hidden" name="form-name" />
                        <h2 className='text-3xl font-semibold my-8 text-color-text-dark'>General Details</h2>
                        <div className='flex items-center justify-between w-full flex-wrap'>
                            <Input label="Company" value={values.company} name="company"/>
                            <Input label="VAT" value={values.VAT} name="VAT"/>
                        </div>
                        <div className='flex items-center justify-between w-full flex-wrap'>
                            <Input label="Website" value={values.website} name="website"/>
                            <Input label="Email" value={values.email} name="email"/>
                        </div>
                        <div className='flex items-center justify-between w-full flex-wrap'>
                            <Input label="First Name" value={values.firstName} name="firstName"/>
                            <Input label="Last Name" value={values.lastName} name="lastName"/>
                        </div>
                        <div className='flex items-center justify-between w-full flex-wrap'>
                            <Input label="Phone Number" value={values.phoneNumber} name="phoneNumber"/>
                            <Input label="Country" value={values.country} name="country"/>
                        </div>
                            <Input type="full" label="Address" value={values.address} name="address"/>
                        <h2 className='text-3xl font-semibold my-8 text-color-text-dark'>Your Experience</h2>
                        <div role="group" aria-labelledby="experience-radio-group">
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="experience" value="This would be my first time." />
                                <p className='w-[calc(100%-56px)]'>
                                 This would be my first time.
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="experience" value="I have experience importing products and I’m looking for a reliable agent in China." />
                                <p className='w-[calc(100%-56px)]'>
                                 I have experience importing products and I’m looking for a reliable agent in China.
                                </p>
                            </label>
                        </div>
                        <h2 className='text-3xl font-semibold my-8 text-color-text-dark'>Request Details</h2>
                        <div className='flex items-center justify-between w-full flex-wrap'>
                            <Input label="Industry" value={values.industry} name="industry"/>
                            <Input label="Product" value={values.product} name="product"/>
                        </div>
                        <h3 className='text-2xl font-medium my-8'>Planned Investment Amount</h3>
                        <div role="group" aria-labelledby="investment-radio-group">
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="investment" value="less than $10 000" />
                                <p className='w-[calc(100%-56px)]'>
                                    less than $10 000
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="investment" value="$10 000 - $30 000" />
                                <p className='w-[calc(100%-56px)]'>
                                    $10 000 - $30 000
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="investment" value="$30 000 and up" />
                                <p className='w-[calc(100%-56px)]'>
                                    $30 000 and up
                                </p>
                            </label>
                        </div>
                        <h3 className='text-2xl font-medium my-8'>Cooperation Expectations</h3>
                        <div role="group" aria-labelledby="expectations-radio-group">
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="expectations" value="One-time order" />
                                <p className='w-[calc(100%-56px)]'>
                                    One-time order
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="expectations" value="Repeatedly" />
                                <p className='w-[calc(100%-56px)]'>
                                    Repeatedly
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="expectations" value="Not sure yet" />
                                <p className='w-[calc(100%-56px)]'>
                                    Not sure yet
                                </p>
                            </label>
                        </div>
                        <h3 className='text-2xl font-medium my-8'>How should we contact you?</h3>
                        <div role="group" aria-labelledby="contact-method-radio-group">
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="contactMethod" value="Email" />
                                <p className='w-[calc(100%-56px)]'>
                                    Email
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="contactMethod" value="Phone" />
                                <p className='w-[calc(100%-56px)]'>
                                    Phone
                                </p>
                            </label>
                            <label className="flex items-center text-lg">
                                <Field className="w-10 h-10 mr-4 my-2 rounded-none block" type="radio" name="contactMethod" value="Personal visit in Bydgoszcz/Gdańsk" />
                                <p className='w-[calc(100%-56px)]'>
                                    Personal visit in Bydgoszcz/Gdańsk
                                </p>
                            </label>
                        </div>
                        <Input type="textarea" label="Additional Comment" value={values.comment} name="comment"/>
                        <div className='flex justify-end'>
                            <CallToAction behaviour="submit" type="submit" className="my-4 bg-brand text-white" hover="hover:bg-color-text-dark" disabled={isSubmitting}>Send Request</CallToAction>
                        </div>
                            {/*<div className='flex flex-col items-center xl:items-start'>*/}
                            {/*    {errors.email && touched.email ? (*/}
                            {/*        <p className="text-lg text-brand">{errors.email}</p>*/}
                            {/*    ) : null}*/}
                            {/*</div>*/}
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default QuoteForm