import React from 'react';
import Headline from "../components/Headline";
import CallToAction from "../components/CallToAction";
import Section from "../components/Section";

function ThankYou() {
    return (
        <Section>
            <div className='w-full flex flex-col mt-32 justify-center items-center'>
                <Headline noAnimation className="my-4">Thank you for your submission.</Headline>
                <p>Our team will get in touch with you within one business day.</p>
                <CallToAction type="link" className="underline text-color-text-dark" href="/">Back Home</CallToAction>
            </div>
        </Section>
    );
}

export default ThankYou;