const services = [
    {
        name: "OEM and ODM Solutions",
        description: "Transform your vision into reality with our OEM (Original Equipment Manufacturing) and ODM (Original Design Manufacturing) services. We collaborate closely with skilled manufacturers to create products that bear your unique brand identity. Whether you’re looking for customization or innovative design, our expertise ensures products that stand out in the market.",
        href: "/services/oemodm"
    },
    {
        name: "1688.com",
        description: "Navigate the vast landscape of China’s online marketplace with confidence. Our 1688.com import services empower you to tap into a world of suppliers, products, and possibilities. We streamline the sourcing process, ensuring reliable suppliers and high-quality products that align with your business goals.",
        href: "/services/1688"

    },
    {
        name: "Full Import Assistance",
        description: "Let us shoulder the complexities. We handle everything from sourcing to shipping, ensuring seamless transactions and quality products.",
        href: "/services/full-import"

    },
    {
        name: "Trademark Registration in China",
        description: "Safeguard your brand’s identity with our trademark registration services in China. Intellectual property is a valuable asset, and we guide you through the intricacies of registering and protecting your trademarks, preventing unauthorized use and infringement.",
        href: "/services/company-registration-ch"

    },
    {
        name: "LLC Company Registration in Hong Kong",
        description: "Unlock the advantages of a business-friendly environment with our Hong Kong LLC company registration services. Establish your presence in an international financial hub, facilitating smooth business operations and international trade.",
        href: "/services/company-registration-hk"

    },
    {
        name: "Legal Company Formation in Poland",
        description: "For beginners, navigating the legal aspects of business can be daunting. Our services extend to legal company formation in Poland, offering step-by-step guidance to help you establish a legal entity and set the foundation for your business success.",
        href: "/services/company-registration-pl"
    },
    {
        name: "Website Creation",
        description: "For beginners, navigating the legal aspects of business can be daunting. Our services extend to legal company formation in Poland, offering step-by-step guidance to help you establish a legal entity and set the foundation for your business success.",
        href: "/services/website-creation"
    }
]

const chinaTrademark = [
    {
        name: "International Brands",
        description: "If you're looking to expand your brand's footprint in China, securing your trademark is the first step to ward off counterfeits and maintain brand integrity.",
    },
    {
        name: "Startups and Innovators",
        description: "For budding businesses, a trademark ensures that your brand identity remains distinct right from the outset. It helps in building trust among consumers and potential investors.",
    },
    {
        name: "Ecommerce Platforms",
        description: "With the booming online market in China, e-commerce businesses must protect their brand name and logo to ensure customers know they're shopping from the original source.",
    },
    {
        name: "Manufacturers and Exporters",
        description: "If you're producing goods in China for the global market, registering your trademark prevents local copycats from undermining your brand's reputation.",
    },
    {
        name: "Franchise Businesses",
        description: "For businesses looking to franchise in China, a registered trademark is crucial. It guarantees that the franchise's brand value remains consistent across various outlets.",
    },
    {
        name: "Creative Professionals",
        description: "Designers, artists, and other creators looking to market their original creations in China should secure their unique symbols, logos, or brand names.",
    },
    {
        name: "Tech Companies and App Developers",
        description: "With China's rapidly advancing tech industry, it's essential for tech firms and app developers to safeguard their software icons, brand names, and logos against imitations.",
    },
]
const hongkongCompany = [
    {
        name: "Entrepreneurs & Startups",
        description: "EU entrepreneurs looking to tap into Asian markets can benefit from Hong Kong's business-friendly environment. With GoodyGo, the process can be simplified, making it easier for startups and solo entrepreneurs.",
    },
    {
        name: "Trade & Export-Import Businesses",
        description: "For EU businesses engaged in trade between Europe and Asia, Hong Kong serves as an ideal gateway. GoodyGo's local expertise can facilitate smoother business operations",
    },
    {
        name: "Investors & Asset Managers",
        description: "EU citizens aiming to manage investments or assets in the Asia-Pacific region might find Hong Kong's regulatory framework and financial infrastructure attractive. GoodyGo can guide investors through the process.",
    },
    {
        name: "Investors & Asset Managers",
        description: "For EU citizens running online businesses, Hong Kong offers a global hub with its advanced IT infrastructure. Partnering with GoodyGo ensures that even remote business owners can navigate company registration with ease.",
    },
    {
        name: "Consultants & Service Providers",
        description: "If you're an EU consultant or provide specialized services targeting Asian clients, establishing a Hong Kong company can enhance your professional image and expand your client base. GoodyGo's streamlined services can be especially beneficial.",
    },
    {
        name: "Holding Companies",
        description: "EU businesses looking for tax-efficient structures might consider Hong Kong for setting up holding companies, given its favorable tax regime. GoodyGo's expertise can make this process less daunting.",
    },
    {
        name: "Intellectual Property Management",
        description: "EU companies wanting to protect their IP in the Asian market can leverage Hong Kong's robust legal framework. GoodyGo can assist in ensuring all legalities are addressed.",
    },
    {
        name: "EU Companies Expanding East",
        description: "For European companies planning to expand their operations in Asia, Hong Kong can serve as a strategic outpost. GoodyGo's familiarity with both European and Asian business norms can bridge the gap.",
    },
]

export {services, chinaTrademark, hongkongCompany}