import {faq} from '../utils/faq.js'
import AccordionItem from "../components/AccordionItem";
import Headline from "../components/Headline";

function Faq({headline="Frequently Asked Questions", data=faq, multicolumn}) {
    return (
        <div className='flex flex-col items-center'>
            <Headline className='mb-8'>{headline}</Headline>
            <div className={`${multicolumn?"flex flex-wrap justify-center items-start":"w-full"}`}>
                {
                    data.map(q => {
                        return <AccordionItem multicolumn={multicolumn} question={q.question} answer={q.answer}/>
                    })
                }
            </div>
        </div>
    );
}

export default Faq;