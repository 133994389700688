import React from 'react';
import Section from "../../../components/Section";
import ImageWithText from "../../../sections/ImageWithText";
import polandBlob from "../../../assets/img/company-poland.png";
import CTA from "../../../sections/CTA";
import QuoteForm from "../../../components/contact/QuoteForm";
import ContactForm from "../../../components/contact/ContactForm";
import Headline from "../../../components/Headline";

function Poland() {
    return (
        <>
            <div className='mt-32'></div>
            <Section name='china'>
                <ImageWithText heading="Unlock Your Business Potential in Poland's Thriving Market"
                               content="Poland, a dynamic and growing market in the heart of Europe, presents abundant opportunities for businesses seeking to expand. Navigating the intricacies of company registration is a breeze with GoodyGo. Our expertise and personalized guidance ensure a seamless entry into this vibrant economic landscape."
                               image={polandBlob}
                />
            </Section>
            <Section>
                <CTA />
            </Section>
        </>
    );
}

export default Poland;