import {ReactComponent as ChinaIcon} from "../assets/icons/china.svg";
import {ReactComponent as LinkIcon} from "../assets/icons/link.svg";
import {ReactComponent as ExperienceIcon} from "../assets/icons/experience.svg";
import {ReactComponent as CardIcon} from "../assets/icons/credit-card.svg";
import {ReactComponent as ChecklistIcon} from "../assets/icons/checklist.svg";
import {ReactComponent as LanguageIcon} from "../assets/icons/language.svg";
import {ReactComponent as DesignIcon} from "../assets/icons/design.svg";
import {ReactComponent as DevelopmentIcon} from "../assets/icons/development.svg";
import {ReactComponent as ExpertiseIcon} from "../assets/icons/expertise.svg";
import {ReactComponent as EcommerceIcon} from "../assets/icons/ecommerce.svg";
import {ReactComponent as AdaptiveIcon} from "../assets/icons/adaptive.svg";
import {ReactComponent as OwnershipIcon} from "../assets/icons/ownership.svg";
import {ReactComponent as CostEfficiencyIcon} from "../assets/icons/cost-efficient.svg";
import {ReactComponent as EaseIcon} from "../assets/icons/ease.svg";
import {ReactComponent as WorldIcon} from "../assets/icons/world.svg";

const getIconFromName=(iconName, iconClass) => {
    switch (iconName){
        case "china":
            return <ChinaIcon className={iconClass} />
        case "link":
            return <LinkIcon className={iconClass} />
        case "experience":
            return <ExperienceIcon className={iconClass} />
        case "card":
            return <CardIcon className={iconClass} />
        case "checklist":
            return <ChecklistIcon className={iconClass} />
        case "language":
            return <LanguageIcon className={iconClass} />
        case "design":
            return <DesignIcon className={iconClass} />
        case "development":
            return <DevelopmentIcon className={iconClass} />
        case "expertise":
            return <ExpertiseIcon className={iconClass} />
        case "ecommerce":
            return <EcommerceIcon className={iconClass} />
        case "cost-efficient":
            return <CostEfficiencyIcon className={iconClass} />
        case "adaptive":
            return <AdaptiveIcon className={iconClass} />
        case "ownership":
            return <OwnershipIcon className={iconClass} />
        case "ease":
            return <EaseIcon className={iconClass} />
        case "world":
            return <WorldIcon className={iconClass} />
        default:
            return null;
    }
}

export {getIconFromName}