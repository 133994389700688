import React from 'react'

function Section({ name, children }) {
    return (
        <section className='reveal-fade page__width my-[15%]' id={name}>
            {children}
        </section>
    )
}

export default Section