import {ReactComponent as ArrowIcon} from "../assets/icons/arrow.svg";

function ServiceAccordionItem({name, href, description}) {
    return (
        <a href={href} className="flex flex-col bg-white text-start w-full lg:w-2/5 mx-4 rounded-2xl w-1/3 border-[#f0f0f0] hover:shadow-nav-dropdown transition-all duration-300 mb-4 lg:mb-8 p-8 lg:p-12 border">
            <div className='flex w-full items-center justify-between'>
                <div className='flex flex-col items-start'>
                <h3 className="text-xl text-color-text-dark font-semibold">{name}</h3>
                <p className='text-color-text-light text-xs'>
                    {description}
                </p>
                </div>
                {href?<ArrowIcon className="w-4 h-4 -rotate-90 fill-color-text-dark" />:null}
            </div>
        </a>
    );
}

export default ServiceAccordionItem;