import Headline from "../components/Headline";
import CallToAction from "../components/CallToAction";
import {getIconFromName} from "../utils/icons";
import MulticolumnCard from "../components/MulticolumnCard";

function Multicolumn({headline, data}) {
    return (
        <div className='flex flex-col items-center w-full'>
            <Headline className='mb-8'>{headline}</Headline>
            <div className='w-full flex flex-col lg:flex-row items-start gap-8 my-8'>
            {
                data.map(c => {
                    console.log({c})
                    const icon = getIconFromName(c.icon, c.iconStyle)
                    return <MulticolumnCard icon={icon} heading={c.heading} content={c.content} />
                })
            }
            </div>
            <CallToAction href="/quote" type="main">Get a Free Quote</CallToAction>
        </div>
    );
}

export default Multicolumn;