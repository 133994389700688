import { Route, Routes } from 'react-router-dom'
import './globals.css'
import Home from './routes/Home'
import Quote from './routes/./Quote'
import About from './routes/About'
import SixteenEightyEight from './routes/services/SixteenEightyEight'
import NotFound from './routes/NotFound'
import Contact from "./routes/Contact";
import {useEffect} from "react";
import Lenis from "@studio-freight/lenis";
import gsap from 'gsap'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SplitType from "split-type";
import ThankYou from "./routes/ThankYou";
import Services from "./routes/Services";
import OEM from "./routes/services/OEM";
import FullImport from "./routes/services/FullImport";
import Poland from "./routes/services/company-registration/Poland";
import HongKong from "./routes/services/company-registration/HongKong";
import China from "./routes/services/company-registration/China";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import WebsiteCreation from "./routes/services/WebsiteCreation";

export default function App() {
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        const lenis = new Lenis({
            duration: 2
        })
        function raf(time){
            lenis.raf(time)
            requestAnimationFrame(raf)
        }
        requestAnimationFrame(raf)


        const splitTypes = document.querySelectorAll('.reveal-type')
        const fades = document.querySelectorAll('.reveal-fade')

        splitTypes.forEach((char, i) => {
            const text = new SplitType(char, { types: 'chars, words' })

            gsap.from(text.chars, {
                scrollTrigger:{
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                },
                y:10,
                opacity: 0,
                stagger: 0.04
            })
        })

        fades.forEach(fade => {
            gsap.from(fade, {
                scrollTrigger:{
                    trigger: fade,
                },
                y:25,
                opacity: 0,
            })
        })

        gsap.from('.reveal-process', {
            scrollTrigger:{
                trigger: '.reveal-process',
            },
            x:25,
            stagger: 0.4,
            opacity: 0,
        })
        gsap.from('.reveal-mc', {
            scrollTrigger:{
                trigger: '.reveal-mc',
            },
            y:25,
            stagger: 0.4,
            opacity: 0,
        })
        gsap.from('.reveal-faq', {
            scrollTrigger:{
                trigger: '.reveal-faq',
            },
            y:25,
            stagger: 0.4,
            opacity: 0,
        })
    }, []);

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/services' element={<Services />} />
      <Route path='/services/1688' element={<SixteenEightyEight />} />
      <Route path='/services/oemodm' element={<OEM />} />
      <Route path='/services/full-import' element={<FullImport />} />
      <Route path='/services/company-registration-pl' element={<Poland />} />
      <Route path='/services/company-registration-hk' element={<HongKong />} />
      <Route path='/services/company-registration-ch' element={<China />} />
      <Route path='/services/website-creation' element={<WebsiteCreation />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/quote' element={<Quote />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/thankyou' element={<ThankYou />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}