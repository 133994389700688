import React from 'react'
import SidebarLink from './SidebarLink'

function NavLink({ href, label, dropdown, children, className }) {
    return (
        <li className='group ml-2 relative cursor-pointer py-4 px-5 text-base items-center flex'>
            <a className={`group-hover:text-brand text-color-text-dark ${className}`} href={href}>{label?label:children}</a>
            {dropdown ?
                <div className='hidden group-hover:flex flex-col absolute rounded-lg w-80 top-full bg-white border border-[#f1f4ff] shadow-nav-dropdown'>
                    <ul>
                        {
                            dropdown.map(link => {
                                return <SidebarLink dropdown={link.submenu} href={link.href}>{link.label}</SidebarLink>
                            })
                        }
                    </ul>
                </div>
                : null}
        </li>
    )
}

export default NavLink