import {ReactComponent as ArrowIcon} from "../assets/icons/arrow.svg";
import {useState} from "react";

function AccordionItem({question, answer, multicolumn}) {
    const [open, setOpen] = useState(false)

    return (
        <button onClick={() => setOpen(!open)} className={`reveal-faq flex flex-col bg-white text-start w-full ${multicolumn?"lg:w-2/5 mx-4":"lg:w-2/3 mx-auto"} rounded-2xl border-color-text-dark mb-8 p-8 lg:p-12 ${open?'shadow-faq border-none':'border'}`}>
            <div className='flex w-full items-center justify-between'>
                <h3 className="text-xl lg:text-2xl text-color-text-dark font-semibold">{question}</h3>
                <ArrowIcon className={`w-8 h-8 transition-all duration-300 fill-color-text-dark ${open?"rotate-180":"rotate-0"}`} />
            </div>
            <div className={`${open?"mt-8":"hidden"}`}>
                <p className="text-color-text-light">{answer}</p>
            </div>
        </button>
    );
}

export default AccordionItem;