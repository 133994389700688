import React from 'react';
import {Field} from "formik";

function Input({value, name, type, label}) {
    return (
        <div className={`flex flex-col my-4 items-start ${type==="full"||type==="textarea"?"w-full":'w-full lg:w-[calc(50%-12px)]'}`}>
            <label className='text-color-text-dark block font-medium mb-2 text-lg'>{label}</label>
            <Field component={type==="textarea"&&"textarea"} rows={type==="textarea"&&"4"} name={name} value={value} type="text" className={`${type==="textarea"&&"resize-none"} w-full font-normal text-color-text-dark bg-transparent border border-color-text-light rounded-md text-lg p-4`} />
        </div>
    );
}

export default Input;