import React from 'react';
import Headline from "./Headline";
import ServiceAccordionItem from "./ServiceAccordionItem";

function ServicesAccordion({headline, data}){
    return (
        <div className='flex flex-col items-center'>
            <Headline className='mb-8'>{headline}</Headline>
            <div className="flex flex-wrap justify-center items-start">
                {
                    data.map(q => {
                        return <ServiceAccordionItem description={q.description} name={q.name} href={q.href}/>
                    })
                }
            </div>
        </div>
    );
}

export default ServicesAccordion;