import { process } from '../utils/process'
import ProcessStep from "../components/ProcessStep";
import CallToAction from "../components/CallToAction";
import Headline from "../components/Headline";

function Process({
                     headline="Full Import from China",
                     description="Importing goods from China is easy and transparent with GoodyGo. We have a clearly defined process for each of our clients",
                     data=process
}) {
    return (
        <div className='flex flex-col items-center'>
            <Headline className='mb-8'>{headline}</Headline>
            <p className='text-color-text-light text-center w-full lg:w-1/2 text-lg -mt-4 mb-4'>{description}</p>
            <div className='py-20 overflow-hidden relative h-auto after:content-[""] after:absolute after:bottom-0 after:h-20 after:w-4 after:left-0 after:right-0 after:m-auto'>
                {
                    data.map((step, index) => {
                        return <ProcessStep position={step.position}
                                     headline={step.headline}
                                     description={step.description}
                                    index={index}
                                    length={data.length}
                        />
                    })
                }
            </div>
            <CallToAction type="main" href="/quote">Get a Free Quote</CallToAction>
        </div>
    );
}

export default Process;