import React from 'react';
import Headline from "../components/Headline";
import CallToAction from "../components/CallToAction";
import Section from "../components/Section";

function NotFound() {
    return (
        <Section>
            <div className='w-full flex flex-col mt-32 justify-center items-center'>
                <Headline noAnimation className="text-9xl text-brand">404</Headline>
                <Headline noAnimation className="my-4">Unlike our products, you got lost.</Headline>
                <p>The page you're trying to reach does not exist.</p>
                <CallToAction type="link" className="underline text-color-text-dark" href="/">Let's take you back home</CallToAction>
            </div>
        </Section>
    );
}

export default NotFound;