import React from 'react';
import Section from "../components/Section";
import FAQ from "../sections/FAQ";
import { services } from "../utils/services";
import CallToAction from "../components/CallToAction";
import CTA from "../sections/CTA";
import ServicesAccordion from "../components/ServicesAccordion";

function Services() {
    return (
        <>
            <div className='mt-32'></div>
            <Section name="FAQ">
                <ServicesAccordion headline="Services we offer" data={services}/>
            </Section>
            <Section name="CTA">
                <CTA />
            </Section>
        </>
    );
}

export default Services;