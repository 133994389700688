import heroImage from '../assets/img/hero-desktop.png'
import mobileHero from '../assets/img/truck-mobile-2.png'
import CallToAction from "./CallToAction";
import {ReactComponent as ArrowIcon} from "../assets/icons/arrow-long.svg";
import ImageWrapper from "./ImageWrapper";

function Hero() {
    return (
        <div className='relative flex flex-col items-center justify-center min-h-screen mt-16 lg:mt-0'>
            <div className="page__width flex flex-col lg:flex-row items-center justify-between">
                <ImageWrapper className="xl:hidden w-8/12 lg:w-2/5 mb-4" src={mobileHero}/>
                <div className="text-center lg:text-start w-full lg:w-1/2">
                    <h1 className="reveal-type text-4xl xl:text-5xl font-extrabold mb-4 text-color-text-dark">China's best products, swiftly delivered <span className='text-brand'>anywhere in Europe.</span></h1>
                    <p className="reveal-fade font-body text-base my-4 text-color-text-light">We specialize in saving money and unnecessary headaches for our clients' businesses while building a solid bridge between Chinese suppliers that supercharge sales processes and customer support.</p>
                    <div className='flex flex-col lg:flex-row items-center'>
                        <CallToAction behaviour="link" className="border-none block mt-4 bg-brand text-white lg:w-auto font-bold text-center" type="main" href="/quote">Get a Free Quote</CallToAction>
                        <CallToAction behaviour="link" className="border-none xl:flex items-center mt-4 lg:w-auto font-bold text-center text-brand group" hover="hover:underline" href="/about">About GoodyGo<ArrowIcon className='hidden lg:block ml-2 group-hover:ml-4 w-4 h-4 transition-all duration-500 stroke-brand'/></CallToAction>
                    </div>
                </div>
                <ImageWrapper className="absolute hidden xl:block right-12 -z-10 w-8/12 lg:w-3/5" src={heroImage}/>
            </div>
        </div>
    );
}

export default Hero;