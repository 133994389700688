import Headline from '../components/Headline'
import CallToAction from '../components/CallToAction'

function Cta({
                 heading="Get a Free Quote Today!",
                 content="Fill out a form and get a quote from us within 24 hours.",
                 cta="Send a Request!",
                 href="/quote"
            }) {
    return (
        <div className='bg-container-lighter py-32 px-8 bg-cover rounded-xl w-full flex flex-col items-center'>
            <Headline className="text-white">{heading}</Headline>
            <p className="text-center text-white my-4 lg:w-1/2 text-lg">{content}</p>
            <CallToAction href={href} hover="hover:bg-opacity-70" className="bg-white rounded-full">{cta}</CallToAction>
        </div>
    );
}

export default Cta;