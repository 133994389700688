import React, {useEffect} from 'react'
import Section from '../components/Section'
import Hero from "../components/Hero";
import Content from "../sections/Content";
import Multicolumn from "../sections/Multicolumn";
import {reasons} from '../utils/multicolumn-data'
import Process from "../sections/Process";
import FAQ from "../sections/FAQ";
import ContactForm from "../components/contact/ContactForm";
import Headline from "../components/Headline";
import CTA from "../sections/CTA";

function Home() {

    return (
        <div>
            <Hero/>
            <Section name="content">
                <Content
                    headline="Beyond Borders, Within Budget."
                    content="We redefine imports with 1688.com expertise, ensuring seamless cross-border transactions while keeping costs in check."
                    cta="What is 1688.com anyway?"
                    href="/services/1688"
                />
            </Section>
            <Section name="process">
                <Process />
            </Section>
            <Section name="why-us">
                <Multicolumn headline="Why Choose Us?" data={reasons}/>
            </Section>
            <Section name="CTA">
                <CTA />
            </Section>
            <Section name="FAQ">
                <FAQ/>
            </Section>
            <Section>
                <Headline>Have Questions? Ask Right Away!</Headline>
                <ContactForm className="w-full lg:w-2/3 mx-auto"/>
            </Section>
            {/*<Section name="testimonials">*/}
            {/*    <Testimonials testimonials={testimonials} />*/}
            {/*</Section>*/}
        </div>
    )
}

export default Home