import React from 'react'
import logo from '../../assets/img/GoodyGo_logo_white.png'
import CallToAction from "../CallToAction";
import {footer} from "../../utils/menus";
import FooterLink from "./FooterLink";
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import {sendContactForm} from "../../utils/contactService";
import ImageWrapper from "../ImageWrapper";
import  {ReactComponent as TikTokIcon} from "../../assets/icons/tiktok.svg";
import  {ReactComponent as InstagramIcon} from "../../assets/icons/instagram.svg";
import  {ReactComponent as FacebookIcon} from "../../assets/icons/facebook.svg";
import  {ReactComponent as LinkedInIcon} from "../../assets/icons/linkedin.svg";
import  {ReactComponent as TwitterIcon} from "../../assets/icons/twitter.svg";

const schema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email.")
        .required("Email is required."),
});
function Footer() {
    return (
        <>
        <div className='py-20 lg:py-40 bg-color-text-dark'>
            <div className="page__width gap-20 w-full flex flex-col lg:flex-row items-start justify-center">
                <div className='flex flex-col w-full lg:w-2/6 items-start'>
                    <ImageWrapper src={logo} className="w-full fill-white" />
                    <div className='flex w-full items-center justify-center lg:justify-between mt-8'>
                        {/*<a href="#" target="_blank">*/}
                        {/*    <FacebookIcon className="w-10 h-10 mx-4 lg:mx-0 stroke-white hover:opacity-60"/>*/}
                        {/*</a>*/}
                        {/*<a href="#" target="_blank">*/}
                        {/*    <TwitterIcon className="w-10 h-10 mx-4 lg:mx-0 stroke-white hover:opacity-60"/>*/}
                        {/*</a>*/}
                        <a href="https://www.tiktok.com/@goodygo91" target="_blank">
                            <TikTokIcon className="w-10 h-10 mx-4 lg:mx-0 stroke-white hover:opacity-60"/>
                        </a>
                        <a href="https://l.instagram.com/?u=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fgoodygo%2F&e=AT0UgFwjB-Wd4K0YwMedjJXZtOSpwJNi25pFBu1lpobRa5gXJ6GUjtFZ7UBjxudzCPckZ7qKgn4VyHL1ZMP3vSg0MnhiNyBkpCd6pP6eUBDmfLCw_BRJ-yZbjhi2o2DvYC_d-V37i_1csajxUAGJ7Q" target="_blank">
                            <LinkedInIcon className="w-10 h-10 mx-4 lg:mx-0 stroke-white hover:opacity-60"/>
                        </a>
                        <a href="https://instagram.com/goodygo_import_china?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank">
                            <InstagramIcon className="w-10 h-10 mx-4 lg:mx-0 stroke-white hover:opacity-60"/>
                        </a>
                    </div>
                </div>
                {
                    Object.keys(footer).map(key => {
                        return <div className='w-full lg:w-1/6 flex flex-col text-center items-center lg:text-start lg:items-start'>
                            <h3 className="text-white text-xl font-semibold mb-2">{key}</h3>
                            <ul>
                                {footer[key].map(link => {
                                    return <FooterLink href={link.href} label={link.label} />
                                })}
                            </ul>
                        </div>
                    })
                }
                <div className="flex flex-col w-full lg:w-2/6 items-center">
                    <h2 className="text-2xl text-white font-bold mb-4 w-full text-center lg:text-start">Stay ahead with China's news & get spreadsheet with high potential products every month.</h2>
                    <Formik
                        initialValues={{
                            "bot-field": "",
                            "form-name": "newsletter",
                            email: '',
                        }}
                        validationSchema={schema}
                        onSubmit={
                            async (values, actions) => {
                                    const body = new FormData();
                                    Object.entries(values).forEach(([ key, val ]) => {
                                        body.append(key, val);
                                    });

                                    await fetch("https://getform.io/f/f6c2ceeb-0eb5-4b5e-b9f8-7c825f2f9487", {
                                        method: "POST",
                                        headers: { accept: "application/json" },
                                        body,
                                    });

                                sendContactForm("newsletter", values)
                                    .then(() => {
                                        console.log("Form sent.")
                                        actions.resetForm()
                                    })
                                    .catch((err) => {
                                        console.log("An error has occurred: ", err)
                                    })
                                    .finally(() => actions.setSubmitting(false))
                        }}
                    >
                        {({handleSubmit}) => (
                            <Form data-netlify={true} netlify={true} name="newsletter" onSubmit={handleSubmit} className='flex flex-col w-full'>
                                <Field type="hidden" name="bot-field" />
                                <Field type="hidden" name="form-name" />
                                <Field type="email" name="email" className="py-4 text-center rounded-md mb-2" placeholder='Enter Your Email'/>
                                <CallToAction type="submit" behaviour="submit">Join the Newsletter!</CallToAction>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
            <div className='w-full bg-color-text-dark'>
                <div className='flex flex-col lg:flex-row items-center justify-between page__width pb-4'>
                    <p className='text-sm mb-2 lg:mb-0 text-[#A0A0A0]'>&copy; GoodyGo {new Date().getFullYear()} | All Rights Reserved</p>
                    <p className='text-sm text-[#A0A0A0]'>Designed with love by <a href="https://cristiantirotta.com" target="_blank" className="font-semibold underline">TirottaWeb</a>️💙️</p>
                </div>
            </div>
    </>
    )
}

export default Footer