const faq = [
    {
        question: "What is 1688.com?",
        answer: "1688.com is a huge B2B portal similar to Alibaba, Made in China, intended only for the Chinese market. It's a kind of wholesaler for the Chinese, or as some call it \"Alibaba for the Chinese\".",
    },
    {
        question: "What is GoodyGo?",
        answer: "GoodyGo is a leading agency specializing in facilitating imports from China to the EU. We assist businesses in navigating the complexities of international trade, ensuring a smooth and efficient process from sourcing to delivery.",
    },
    {
        question: "How does GoodyGo differ from other import agencies?",
        answer: "GoodyGo prides itself on its hands-on approach. Our team is based locally in China, ensuring direct oversight and management of all projects. This local presence allows us to build strong relationships with suppliers and stay updated on market trends.",
    },
    {
        question: "I'm new to importing. Can GoodyGo assist small businesses?",
        answer: "Absolutely! We work with businesses of all sizes, from startups to established enterprises. Our team is dedicated to guiding you through every step, ensuring you feel confident and informed.",
    },
    {
        question: "How does the OEM/ODM service work?",
        answer: "Our OEM (Original Equipment Manufacturer) and ODM (Original Design Manufacturer) services involve partnering with Chinese manufacturers to produce goods under your brand name or design. We handle the entire process, from finding the right manufacturer to quality control and shipping."
    },
    {
        question: "Can GoodyGo help with company registration in China?",
        answer: "Yes, we offer services for company registration in both mainland China and Hong Kong. Our team will guide you through the legal and administrative processes, ensuring your business is set up correctly."
    },
    {
        question: "How do I get started with GoodyGo?",
        answer: "Simply reach out to our team via our contact page or give us a call. We'll discuss your needs and provide tailored solutions to meet your business objectives."
    },
    {
        question: "Are there any hidden fees?",
        answer: "Transparency is a core value at GoodyGo. All fees and charges will be discussed upfront, with no hidden costs. Our goal is to provide value-driven services that enhance your business operations."
    },
    {
        question: "How does GoodyGo ensure the quality of goods?",
        answer: "Quality assurance is paramount to us. We conduct thorough inspections at various stages of production and work closely with manufacturers to ensure all products meet the desired standards."
    },
    {
        question: "I have more questions. How can I reach out?",
        answer: "We're here to help! You can contact our customer support team via email, phone, or by using our website's contact form. We aim to respond to all inquiries within 24 hours."
    }
]

export {faq}