import React from 'react';
import ContactForm from "../components/contact/ContactForm";

function Contact(props) {
    return (
        <div className='page__width flex flex-col mt-40 items-center justify-center'>
            <h2 className='text-color-text-dark font-extrabold text-5xl xl:text-7xl text-center'>Send a Request</h2>
            <p className='text-color-text-light my-4 text-center lg:w-8/12 text-lg'>Don’t be shy, we would love to hear from you. Send us an e-mail at contact@goodygo.com or fill the form below if you have any questions. Do not hesitate, make a first step with us! 🤍</p>
            <ContactForm className="w-full lg:w-2/3 flex flex-col flex-wrap"/>
        </div>
    );
}

export default Contact;