import React from 'react';
import Section from "../../components/Section";
import ImageWithText from "../../sections/ImageWithText";
import intro1688 from "../../assets/img/1688-macbook-mockup.png";
import problem1688 from "../../assets/img/1688-problem.png";
import Multicolumn from "../../sections/Multicolumn";
import {solutions} from "../../utils/multicolumn-data";
import FAQ from "../../sections/FAQ";
import Headline from "../../components/Headline";
import ContactForm from "../../components/contact/ContactForm";

function SixteenEightyEight() {
    return (
        <>
            <Section name="image-with-text">
                <div className='h-16'></div>
                <ImageWithText
                    image={intro1688}
                    heading="What is 1688.com anyway?"
                    content={`1688.com is a huge B2B portal similar to Alibaba, Made in China, intended only for the Chinese market. It's a kind of wholesaler for the Chinese, or as some call it "Alibaba for the Chinese".`}
                />
            </Section>
            <Section name="image-with-text">
                <ImageWithText
                    imageFirst={true}
                    image={problem1688}
                    heading="The Catch with 1688.com"
                    content="Embarking on 1688.com for Chinese imports might seem daunting due to the language barrier, registration hurdles, and complex ordering process. At GoodyGo, our team of experts serves as your bridge, offering a seamless path through these challenges. We speak the language, simplify registration, and navigate intricate listings, ensuring you source products from China with ease."
                />
            </Section>
            <Section name="why-us">
                <Multicolumn headline="How do we solve it?" data={solutions}/>
            </Section>
            <Section name="FAQ">
                <FAQ/>
            </Section>
            <Section>
                <Headline className='mb-8'>Have Questions? Ask Right Away!</Headline>
                <ContactForm className="w-full lg:w-2/3 mx-auto"/>
            </Section>
        </>
    );
}

export default SixteenEightyEight;