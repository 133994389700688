import React from 'react';

function MulticolumnCard({icon, heading, content}) {
    return (
        <div className='reveal-mc flex flex-col w-full lg:w-1/3 items-center text-center'>
            {icon}
            <h3 className="text-2xl font-semibold text-color-text-dark my-4">{heading}</h3>
            <p className='text-lg text-color-text-light w-full'>{content}</p>
        </div>
    );
}

export default MulticolumnCard;