import NavLink from './NavLink'
import logo from '../assets/img/GoodyGo_logo_color.png'
import { ReactComponent as XIcon } from '../assets/icons/x-icon.svg'
import { ReactComponent as BurgerIcon } from '../assets/icons/burger.svg'
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg'
import React, { useState } from 'react'
import { navbar } from '../utils/menus'
import CallToAction from './CallToAction'
import SidebarLink from './SidebarLink'
import ImageWrapper from "./ImageWrapper";

function Navbar() {
    const [open, toggleSidebar] = useState(false)

    return (
        <>
            <nav className={`absolute w-full py-4 bg-${open?'[#FFF]':'transparent'} transition-all duration-500 top-0 left-0 z-50 flex items-center justify-between`}>
                <div className='page__width flex items-center justify-between'>
                    <div className='items-center flex'>
                    <a href="/"><ImageWrapper src={logo} className="w-32 xl:w-40 fill-color-text-dark" /></a>
                        <ul className='ml-4 hidden xl:flex items-center'>
                            {
                                navbar.map(link => {
                                    return <NavLink label={link.label} dropdown={link.submenu} href={link.href}></NavLink>
                                })
                            }
                        </ul>
                    </div>
                    <div className='flex items-center'>
                        <a href="tel:+48787395976" className='hidden font-bold lg:flex items-center mr-8 text-color-text-dark text-xl'>
                            <PhoneIcon className="fill-color-text-dark w-6 h-6 mr-2" />+48 787 395 976
                        </a>
                    <CallToAction behaviour="link" className="hidden xl:block" type="main" href="/quote">Send Request</CallToAction>
                    <button className='xl:hidden' onClick={() => toggleSidebar(!open)}>{open ? <XIcon className='w-6 h-6 mr-8 stroke-color-text-dark' /> : <BurgerIcon className='w-6 h-6 mr-8 stroke-color-text-dark' />}</button>
                    </div>
                </div>
                <div className={`animated__sidebar absolute transition-[.5s] top-full bg-white shadow-faq w-full z-[99] overflow-hidden lg:hidden ${open ? 'max-h-screen' : 'max-h-0'}`}>
                    <ul>
                        {
                            navbar.map(link => {
                                return <SidebarLink dropdown={link.submenu} href={link.href}>{link.label}</SidebarLink>
                            })
                        }
                        <SidebarLink href="/quote" className='font-bold'>
                            Get a Free Quote
                        </SidebarLink>
                        <SidebarLink className="font-bold items-center mr-8 text-color-text-dark text-xl" href="tel:++48787395976">
                            <PhoneIcon className="fill-color-text-dark w-6 h-6 mr-2" />+48 787 395 976
                        </SidebarLink>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar