import React from 'react';
import { Field, Form, Formik } from "formik";
import Input from "./Input";
import CallToAction from "../CallToAction";
import * as Yup from "yup";
import {sendContactForm} from "../../utils/contactService";
import {redirect, useNavigate} from "react-router-dom";


const schema = Yup.object().shape({
    fullName: Yup.string().required(),
    email: Yup.string()
        .email("Invalid email.")
        .required("Email is required."),
    subject: Yup.string().required(),
    message: Yup.string().required()
});
function ContactForm(props) {
    const navigate = useNavigate()
    return (
        <>
            <Formik
                initialValues={{
                    "bot-field": "",
                    "form-name": "contact",
                    fullName: '',
                    email: '',
                    subject: '',
                    message:''
                }}
                validationSchema={schema}
                onSubmit={
                    (values, actions) => {
                        sendContactForm("contact", values)
                            .then(() => {
                                console.log("Form sent.")
                                actions.resetForm()
                                navigate('/thankyou')
                            })
                            .catch((err) => {
                                console.log("An error has occurred: ", err)
                            })
                            .finally(() => actions.setSubmitting(false))
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <Form data-netlify={true} netlify={true} name="contact" onSubmit={handleSubmit} {...props}>
                        <Field type="hidden" name="bot-field" />
                        <Field type="hidden" name="form-name" />
                        <div className='flex items-center justify-between w-full flex-wrap'>
                            <Input label="Full Name" value={values.fullName} name="fullName"/>
                            <Input label="Email" value={values.email} name="email"/>
                        </div>
                        <Input type='full' label="Subject" value={values.subject} name="subject"/>
                        <Input type="textarea" label="Your Message" value={values.message} name="message"/>
                        <div className='flex justify-end'>
                            <CallToAction behaviour="submit" type="submit" className="my-4 bg-brand text-white" hover="hover:bg-color-text-dark" disabled={isSubmitting}>Send Message</CallToAction>
                        </div>
                        {/*<div className='flex flex-col items-center xl:items-start'>*/}
                        {/*    {errors.email && touched.email ? (*/}
                        {/*        <p className="text-lg text-brand">{errors.email}</p>*/}
                        {/*    ) : null}*/}
                        {/*</div>*/}
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ContactForm;