import Footer from './footer/Footer'
import Navbar from './Navbar'
import CookieConsent from "react-cookie-consent";
import ImageWrapper from "./ImageWrapper";
import cookies from '../assets/img/cookies.png'
import CallToAction from "./CallToAction";
import { useNavigate } from "react-router-dom";
function Layout({ children }) {
    const navigate = useNavigate()
    return (
        <div>
            <CookieConsent
                disableStyles
                disableButtonStyles
                enableDeclineButton
                location="bottom"
                buttonWrapperClasses="flex flex-col-reverse mt-4 lg:mt-0 lg:flex-row items-center"
                overlayClasses="fixed left-0 top-0 w-full h-full z-[9999] bg-[#000] bg-opacity-10"
                cookieName="cookies-consent"
                containerClasses="px-[10%] flex flex-col lg:flex-row items-center w-full py-4 z-[999] bottom-0 bg-brand justify-between fixed"
                buttonClasses="text-brand bg-white w-full text-sm reveal-fade whitespace-nowrap block text-center xl:inline-block cursor-pointer border font-bold py-4 px-9 tracking-[1px] transition-all duration-500 border-brand bg-brand"
                expires={150}
                overlay
                declineButtonText="Learn more"
                declineButtonClasses="text-white w-full text-sm border-none font-semibold xl:inline-block font-bold py-4 transition-all lg:mr-8"
                onDecline={() => {
                    navigate('/privacy-policy')
                }}
            >
                <div className='flex flex-col lg:flex-row items-center justify-between'>
                    <div className='flex flex-col lg:flex-row items-center'>
                        <ImageWrapper src={cookies} className="w-24 h-24" />
                        <div className='flex flex-col ml-4'>
                            <h3 className='text-xl text-center lg:text-start font-bold text-white'>We use cookies.</h3>
                            <p className='w-full text-center lg:text-start text-sm text-white'>We collect cookies to analyze our website traffic and performance; we never collect any personal data</p>
                        </div>
                    </div>
                </div>
            </CookieConsent>
            <Navbar />
                {children}
            <Footer />
        </div>
    )
}

export default Layout