import React from 'react';
import {ReactComponent as Stepper} from "../assets/icons/process-stepper.svg";

function ProcessStep({position, headline, description, index, length}) {

    const styling = position==="right"
        ?'relative block float-right lg:border-l-2 lg:border-dashed lg:border-brand lg:px-12 before:w-8 before:rounded-full before:top-0 before:-left-4 before:h-8 before:bg-brand lg:before:content-[""] before:absolute'
        :'relative block float-left lg:text-end lg:px-12 lg:lg:border-r-2 lg:border-dashed border-brand before:w-8 before:rounded-full before:top-0 before:-right-4 before:h-8 before:bg-brand lg:before:content-[""] before:absolute';

    return (
        <div className={`${styling} reveal-process lg:mb-0 before:content-none w-full lg:w-[50.1%] text-start border-none px-0`}>
            <h2 className='reveal-type text-3xl font-bold text-color-text-dark mb-4'>{headline}</h2>
            <h2 className={`-z-50 text-[#E5E5E5] text-[12rem] font-bold absolute opacity-80 right-0 -top-16 ${index%2===1?"-":""}rotate-[10deg] lg:hidden`}>{index + 1}</h2>
            <p className='text-color-text-light text-lg'>{description}</p>
            {
                index===length - 1?
                null
                    :
                <Stepper className={`lg:hidden w-full h-auto mt-4 ${index%2===0?'scale-x-[-1]':''}`}/>
            }
        </div>
    );
}

export default ProcessStep;