const navbar = [
    {
        href: "/about",
        label: "About",
    },
    {
        href: "/services",
        label: "Services",
        submenu: [
            {
                href: "/services/oemodm",
                label: "OEM/ODM Solutions"
            },
            {
                href: "/services/full-import",
                label: "Import from China"
            },
            {
                href: "/services/company-registration-ch",
                label: "China Trademark Registration"
            },
            {
                href: "/services/company-registration-pl",
                label: "Poland Company Registration"
            },
            {
                href: "/services/company-registration-hk",
                label: "Hong Kong Company Registration"
            },
            {
                href: "/services/website-creation",
                label: "Website Creation"
            },
        ]
    },
    {
        href: "/services/1688",
        label: "1688.com",
    },
    {
        href: "/contact",
        label: "Contact",
    },
]

const footer = {
    Navigation: [
        {
            href: "/",
            label: "Home",
        },
        {
            href: "/about",
            label: "About",
        },
        {
            href: "/services",
            label: "Services",
        },
        {
            href: "/services/1688",
            label: "1688.com",
        },
        {
            href: "/quote",
            label: "Get a Free Quote",
        },
    ],
    Help:[
        // {
        //     href: "/duty-cost-calculator",
        //     label: "Duty Cost Calculator",
        // },
        {
            href: "/contact",
            label: "Contact",
        },
        {
            href: "/privacy-policy",
            label: "Privacy Policy",
        },
        {
            href: "/terms-and-conditions",
            label: "Terms & Conditions",
        },
    ]
}
export { navbar, footer }