import React from 'react'

function CallToAction(props) {

    // ghost / main / hero
    // hover
    // button / link
    let style;
    switch (props.type) {
        case "main":
            style = "border-brand bg-brand";
            break;
        case "ghost":
            style = "border-white bg-transparent ";
            break;
        case "submit":
            style="border-none bg-brand text-white"
            break;
        default:
            style = "border-none text-brand bg-transparent";
            break;
    }

    return (
        <>
            {
                props.behaviour === "submit"
                    ? <button {...props} className={` reveal-fade block w-full xl:w-auto text-center xl:inline-block cursor-pointer border font-bold py-4 px-9 tracking-[1px] transition-all duration-500 ${style} ${props.hover} ${props.className}`}>{props.children}</button>
                    : <a {...props} className={` reveal-fade block w-full xl:w-auto text-center xl:inline-block cursor-pointer border font-bold py-4 px-9 Ctransition-all duration-500 ${style} ${props.hover} ${props.className}`}>{props.children}</a>
            }
        </>
    )
}

export default CallToAction