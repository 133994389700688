const process = [
    {
        position: "right",
        headline: "Finding Goods & Suppliers",
        description: "From among hundreds of Chinese suppliers offering interesting products, we find a few of the best. Then, based on your needs and the most favorable conditions of import from China, we make a detailed selection, as a result of which we select the best Chinese suppliers for you."
    },
    {
        position: "left",
        headline: "Initial Business Negotiations",
        description: "We start negotiations with several suppliers at the same time. We arrange the best price for you and set the minimum order quantity (MOQ). We check and verify certificates, technical conditions and set delivery terms."
    },
    {
        position: "right",
        headline: "Initial Verification of Suppliers",
        description: "At the beginning, we check if the Chinese manufacturers are reliable using official websites and our experience to avoid problems later."
    },
    {
        position: "left",
        headline: "Supplier Selection & Factory Visits",
        description: "We visit the chosen factories, make deals, and sometimes pick one if it's the best. If we need different products, we work with multiple factories and send all products together."
    },
    {
        position: "right",
        headline: "Signing the Contract & Advance Payment",
        description: "After selecting a factory and confirming details, we put everything in a contract and you pay a portion upfront, usually 30% but sometimes 50%."
    },
    {
        position: "left",
        headline: "Production Control",
        description: "We keep an eye on your product production in China, checking samples and sharing updates with you."
    },
    {
        position: "right",
        headline: "Post-production Control & Container loading Inspection",
        description: "Once production finishes, we check the products again, and if they're good, you pay the remaining amount. We also inspect the goods before they're loaded into containers."
    },
    {
        position: "left",
        headline: "Shipping from China",
        description: "We arrange shipping from China to your location with the help of a customs agency."
    },
    {
        position: "right",
        headline: "Customs clearance at the port of destination – Import duty from China",
        description: "In your country, we clear the products through customs, and you pay us for our services and transportation costs."
    },
]


export {process}