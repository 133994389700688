import ImageWithText from "../../sections/ImageWithText";
import oem from '../../assets/img/OEM.png'
import odm from '../../assets/img/ODM.png'
import Section from "../../components/Section";
import React from "react";
import FAQ from "../../sections/FAQ";
import Headline from "../../components/Headline";
import ContactForm from "../../components/contact/ContactForm";

function Oem() {
    return (
        <>
            <div className='mt-32'></div>
            <Section name="image-with-text">
                <ImageWithText
                    image={oem}
                    content="In China, when you order products from factories, they often allow small changes like different colors or logos without extra charges. But if your order is small, they might ask for more money for these changes, especially if it involves making new molds or special colors. So, if you want changes and don't want to pay extra, it's better to order a larger quantity of products. Otherwise, it might not be cost-effective."
                    heading='OEM Solutions'
                />
            </Section>
            <Section name="image-with-text">
                <ImageWithText
                    image={odm}
                    content="ODM in China is like building with clear instructions. You give us designs and details, we find a factory, and they make the product just as you want it. We talk with you and the factory until you're totally happy with the product. We fully guarantee your satisfaction!"
                    heading='ODM Solutions'
                    imageFirst
                />
            </Section>
            <Section name="FAQ">
                <FAQ/>
            </Section>
            <Section>
                <Headline className='mb-8'>Have Questions? Ask Right Away!</Headline>
                <ContactForm className="w-full lg:w-2/3 mx-auto"/>
            </Section>
        </>
    );
}

export default Oem;